import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import Paul from "images/team/paul.jpeg"
import Bethan from "images/team/Bethan.jpg"
import Sandy from "images/team/Dr-Sandy-Willmott-prof.jpg"
import George from "images/team/George-Williams.jpg"
import Hayley from "images/team/Hayley-Green.jpg"
import Helen from "images/team/Helen-Morris.jpg"
import Armstrong from "images/team/Kai-Armstrong-prof.png"
import LeiZhang from "images/team/Lei-Zhang.png"
import MaryWilliams from "images/team/Mary-Williams.jpg"
import Xujiong from "images/team/Xujiong.jpg"
import Yan from "images/team/yan.jpg"
import Sally from "images/team/Sally-Edwards.jpg"
import Nikki from "images/team/Nikki-Travis.jpg"
import {Container, ContentWithPaddingXl} from "components/misc/Layouts.js";
import {SectionHeading, Subheading as SubheadingBase} from "components/misc/Headings";
import {PrimaryButton as PrimaryButtonBase} from "../misc/Buttons";

const HeadingContainer = tw.div``
const Heading = tw(SectionHeading)``
const Subheading = tw(SubheadingBase)`text-center mb-3`
const Description = tw.p`mt-4 text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100 max-w-xl text-center`;

const Cards = tw.div`flex flex-wrap flex-row justify-center sm:max-w-2xl lg:max-w-5xl mx-auto`
const Card = tw.div`mt-24 w-full sm:w-1/2 lg:w-1/3 flex flex-col min-h-full items-center`
const CardImage = styled.img`
  ${tw`w-64 h-64 bg-contain bg-center rounded`}
`
const CardContent = styled.div`
  ${tw`flex flex-col items-center mt-6`}
  .position {
    ${tw`uppercase font-bold tracking-widest text-xs text-primary-500`}
  }
  .name {
    ${tw`mt-1 text-xl font-medium text-gray-900`}
  }
    .description {
    ${tw`mt-2 text-base font-normal text-gray-700`}
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4; /* Number of lines to show */
    -webkit-box-orient: vertical;
  }
`
const Button = styled(PrimaryButtonBase)`
  ${tw`rounded-full mt-10 uppercase tracking-wider py-4 text-sm hover:shadow-xl transform hocus:translate-x-px hocus:-translate-y-px focus:shadow-outline`}
`;

export default ({
  heading = "Meet These Fine Folks",
  subheading = "Our Team",
  description = "",
  cards  = [
    {
      name: "Prof. Paul Lee",
      title: "Medical Director",
      description: "Prof. Lee is a leading orthopaedic consultant surgeon with a deep understanding of musculoskeletal conditions and…",
      image: Paul,
      profileLink: "https://maimotion.com/prof-paul-lee/"
    },
    {
      name: "Prof. Xujiong Ye",
      title: "Professor of Computer Vision",
      description: "Prof. Ye is renowned for her groundbreaking work in computer vision and medical imaging. With deep expertise…",
      image: Xujiong,
      profileLink: "https://maimotion.com/prof-xujiong-ye/"
    },
    {
      name: "Dr. Lei Zhang",
      title: "AI research scientist",
      description: "Dr. Zhang serves as the Principal AI Research Scientist at MSK Doctors and is a distinguished expert in Multimodal AI and Causal AI. His research…",
      image: LeiZhang,
      profileLink: "https://maimotion.com/dr-lei-zhang/"
    },
    {
      name: "Dr. Bethan Whiting",
      title: "Governance and CQC Registered Manager",
      description: "With a rich background in healthcare governance and a commitment to high-quality medical service provision,…",
      image: Bethan,
      profileLink: "https://maimotion.com/dr-bethan-whiting/"
    },
    {
      name: "Mrs. Helen Morris",
      title: "Senior Physiotherapist",
      description: "Mrs. Morris brings over 20 years of hands-on experience to the MAI-Motion project. As a highly qualified First Contact Practitioner…",
      image: Helen,
      profileLink: "https://maimotion.com/mrs-helen-morris/"
    },
    {
      name: "Sis. Nikki Travis",
      title: "Senior Sister Royal College of Nursing",
      description: "Sister Travis is a cornerstone of the MAI-Motion project, lending over 40 years of extensive hands-on clinical…",
      image: Nikki,
      profileLink: "https://maimotion.com/sis-nikki-travis/"
    },
    {
      name: "Dr. Yan Wen",
      title: "Knowledge Transfer Partnership Data Scientist",
      description: "Dr. Wen brings her expertise in data science to process and interpret the voluminous and complex datasets generated from the…",
      image: Yan,
      profileLink: "https://maimotion.com/dr-yan-wen/"
    },
    {
      name: "Mr. George Williams",
      title: "Senior Software Developer",
      description: "Mr. Williams, with his strong background in software development, is pivotal in the execution and implementation of…",
      image: George,
      profileLink: "https://maimotion.com/mr-george-williams/"
    },
    {
      name: "Mr. Kai Armstrong",
      title: "Senior MAI-Motion Data Scientist",
      description: "Mr. Armstrong’s expertise lies in meticulously extracting and deciphering valuable motion data from MAI-Motion…",
      image: Armstrong,
      profileLink: "https://maimotion.com/kai-armstrong/"
    },
    {
      name: "Ms. Mary Williams",
      title: "Junior MRI Technician",
      description: "Ms. Williams forms a vital part of the team, lending her skills to MRI data extraction, management, and preparation…",
      image: MaryWilliams,
      profileLink: "https://maimotion.com/ms-mary-williams/"
    },
    {
      name: "Mrs. Hayley Green",
      title: "Research Admin Support",
      description: "Mrs. Green serves as the administrative backbone of the MAI-Motion project, orchestrating logistics and streamlining…",
      image: Hayley,
      profileLink: "https://maimotion.com/mrs-hayley-green/"
    },
    {
      name: "Ms. Sally Edwards",
      title: "Senior Research Scientist",
      description: "Ms. Edwards provides critical scientific research support and helps in advancing the project’s research goals…",
      image: Sally,
      profileLink: "https://maimotion.com/ms-sally-edwards/"
    },
    {
      name: "Dr. Sandy Willmott",
      title: "Associate Professor",
      description: "Dr Willmott teaches biomechanics and research modules at both the undergraduate and postgraduate level…",
      image: Sandy,
      profileLink: "https://maimotion.com/ms-sally-edwards/"
    }
  ]
}) => {
  return (
    <Container>
      <ContentWithPaddingXl>
        <HeadingContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          {heading && <Heading>{heading}</Heading> }
          {description && <Description>{description}</Description> }
        </HeadingContainer>
        <Cards>
          {cards.map((card, index) => (
            <Card key={index}>
              <CardImage src={card.image} />
              <CardContent>
                <span className="position">{card.title}</span>
                <span className="name">{card.name}</span>
                <span className="description">{card.description}</span>
                <Button onClick={() => window.open(card.link)}>Read More</Button>
              </CardContent>
            </Card>
          ))}
        </Cards>
      </ContentWithPaddingXl>
    </Container>
  );
};
