import React, { useState } from "react";

import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { ReactComponent as ChevronDownIcon } from "feather-icons/dist/icons/chevron-down.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-7.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-8.svg";

const Subheading = tw(SubheadingBase)`mb-4 text-center`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const Column = tw.div`flex flex-col items-center`;
const HeaderContent = tw.div``;

const FAQSContainer = tw.dl`mt-12 max-w-4xl relative`;
const FAQ = tw.div`cursor-pointer select-none mt-5 px-8 sm:px-10 py-5 sm:py-4 rounded-lg text-gray-800 hover:text-gray-900 bg-gray-200 hover:bg-gray-300 transition duration-300`;
const Question = tw.dt`flex justify-between items-center`;
const QuestionText = tw.span`text-lg lg:text-xl font-semibold`;
const QuestionToggleIcon = styled.span`
  ${tw`ml-2 transition duration-300`}
  svg {
    ${tw`w-6 h-6`}
  }
`;
const Answer = tw.dd`pointer-events-none text-sm sm:text-base leading-relaxed`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-56 w-56 opacity-15 transform translate-x-2/3 -translate-y-12 text-teal-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-15 transform -translate-x-2/3 text-primary-500`}
`;
const HighlightedText = tw.span`text-primary-500`;

export default ({
  subheading = <Subheading>FAQS</Subheading>,
  heading =
    <>
      You have <HighlightedText>Questions ?</HighlightedText>
    </>
  ,
  description = "And we have got answers to all of them.",
  faqs = [
    {
      question: "What is the current status of MAI-Motion?",
      answer:
          "MAI-Motion is currently in the research stage and is not yet available for commercial use. Our dedicated team of researchers and developers is actively working on refining and enhancing the technology to ensure its accuracy, reliability, and usability."
    },
    {
      question: "When can we expect MAI-Motion to be released for commercial use?",
      answer: "We are committed to delivering a high-quality and effective product. While we do not have an exact release date at this time, we are making significant progress in the development of MAI-Motion. We anticipate that it will be available for commercial use in the near future. Please stay tuned for updates and announcements."
    },
    {
      question: "Can I participate in the research or beta testing of MAI-Motion?",
      answer: "We appreciate your interest in contributing to the development of MAI-Motion. At this time, we are not actively seeking external participation in the research or beta testing phase. However, we value collaboration and would be happy to discuss potential partnerships or research opportunities. Please reach out to us with your inquiries."
    },
    {
      question: "Will there be a notification or waiting list for the release of MAI-Motion?",
      answer: "Yes, we understand the anticipation surrounding the release of MAI-Motion. To ensure that you receive updates and notifications about its availability, we encourage you to subscribe to our mailing list. By doing so, you will be among the first to be notified when MAI-Motion is released for commercial use."
    },
    {
      question: "How can I stay informed about the progress of MAI-Motion?",
      answer: "To stay informed about the latest developments and updates regarding MAI-Motion, we recommend visiting our website regularly. Additionally, subscribing to our newsletter and following our social media channels will provide you with timely information and insights into the advancements of MAI-Motion."
    },
    {
      question: "Are there any plans for future expansions or additional modules within MAI-Motion?",
      answer: "As part of our commitment to advancing musculoskeletal motion analysis, we are continuously exploring opportunities for expansion and the development of additional modules within the MAI-Motion framework. While specific plans are yet to be announced, we remain dedicated to expanding the capabilities and applications of this innovative technology.",
      answer1: "If you have any further questions or would like to inquire about research collaborations or partnerships, please don’t hesitate to contact us. We appreciate your interest in MAI-Motion and look forward to sharing more updates with you as we progress towards its commercial release."
    },
    {
      question: "How does MAI-Motion work?",
      answer: "MAI-Motion utilises computer vision, deep learning algorithms, and advanced imaging techniques to capture and analyse motion data. It creates a detailed 3D mesh of the joints, integrates it with 3D volumetric MRI imaging, and applies AI-driven decision support to enhance diagnosis and treatment planning."
    },
    {
      question: "How is MAI-Motion used in clinical settings?",
      answer: "MAI-Motion is versatile and can be used in various clinical settings, including physiotherapy, sports medicine, orthopaedics, and occupational health. It aids in the assessment of musculoskeletal disorders, monitoring progress during rehabilitation, optimising sports performance, and providing valuable data for medical legal reporting."
    },
    {
      question: "Is MAI-Motion suitable for all age groups and conditions?",
      answer: "Yes, MAI-Motion is designed to benefit individuals of all age groups and can be used for a wide range of musculoskeletal conditions. From paediatric patients to seniors, MAI-Motion offers personalised solutions for accurate assessment and effective treatment."
    },
    {
      question: "Is MAI-Motion user-friendly?",
      answer: "Yes, MAI-Motion is designed with user-friendliness in mind. The technology is intuitive, and the software interface is easy to navigate. Healthcare professionals can quickly capture and analyse motion data, providing efficient and effective care to their patients."
    },
    {
      question: "Is MAI-Motion supported by scientific evidence?",
      answer: "Yes, MAI-Motion is backed by extensive research and development. The technology has undergone rigorous testing and validation to ensure its accuracy and reliability. Published studies and case reports demonstrate the effectiveness of MAI-Motion in improving musculoskeletal assessment and patient outcomes."
    },
  ]
}) => {
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = questionIndex => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };

  return (
    <Container>
      <ContentWithPaddingXl>
        <Column>
          <HeaderContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
          </HeaderContent>
          <FAQSContainer>
            {faqs.map((faq, index) => (
              <FAQ
                key={index}
                onClick={() => {
                  toggleQuestion(index);
                }}
                className="group"
              >
                <Question>
                  <QuestionText>{faq.question}</QuestionText>
                  <QuestionToggleIcon
                    variants={{
                      collapsed: { rotate: 0 },
                      open: { rotate: -180 }
                    }}
                    initial="collapsed"
                    animate={activeQuestionIndex === index ? "open" : "collapsed"}
                    transition={{ duration: 0.02, ease: [0.04, 0.62, 0.23, 0.98] }}
                  >
                    <ChevronDownIcon />
                  </QuestionToggleIcon>
                </Question>
                <Answer
                  variants={{
                    open: { opacity: 1, height: "auto", marginTop: "16px" },
                    collapsed: { opacity: 0, height: 0, marginTop: "0px" }
                  }}
                  initial="collapsed"
                  animate={activeQuestionIndex === index ? "open" : "collapsed"}
                  transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                >
                  {faq.answer}
                </Answer>
                {faq.answer1 ? <Answer
                  variants={{
                    open: { opacity: 1, height: "auto", marginTop: "16px" },
                    collapsed: { opacity: 0, height: 0, marginTop: "0px" }
                  }}
                  initial="collapsed"
                  animate={activeQuestionIndex === index ? "open" : "collapsed"}
                  transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                >
                  {faq.answer1}
                </Answer> : null}
              </FAQ>
            ))}
          </FAQSContainer>
        </Column>
      </ContentWithPaddingXl>
      <DecoratorBlob1/>
      <DecoratorBlob2 />
    </Container>
  );
};
